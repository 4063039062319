import './App.css';
import WowProject from './WowProject';

function App() {
  return (
      <WowProject></WowProject>
  );
}

export default App;
